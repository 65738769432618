@import '../../styles/theme';

.add-report-page-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  position: relative;
}

@include media-breakpoint-up(md) {
  .optimize-for-sidebar {
    padding-right: 296px;
  }
}