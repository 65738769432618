.PendingReportScreen {
  .row.mod-fullwidth {
    margin-left: 0;
    margin-right: 0;
  }

  .BenefitCardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }

  .BenefitCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 285px;
    text-align: center;
    padding: 0px 15px;
  }

  .bi-pending-benefits-bg {
    background-image: url(../../assets/images/floating-stories.png);
    background-repeat: repeat-x;
    background-position: 0 450px;
    padding-bottom: 140px;
  }

  .bi-pending-benefits-container {
    margin: 40px auto;
  }

  .bi-pending-benefits-container-benefit-title {
    color: #012b4c;
    font-size: 21px;
    line-height: 29px;
  }

  .bi-pending-benefits-container-benefit-description {
    font-size: 16px;
    line-height: 24px;
  }

  .bi-pending-benefits-container-title {
    font-size: 28px;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: 32px;
    text-align: center;
  }

  .bi-pending-divider {
    max-width: 1140px;
  }

  .bi-pending-header {
    flex-direction: column;
    display: flex;
    align-items: center;
    text-align: center;
    background-image: url(../../assets/images/floating-stories.png);
    background-repeat: repeat-x;
    background-position: -50px -325px;
    padding-bottom: 30px;
    padding-top: 100px;
  }

  .bi-pending-header-btn,
  .bi-pending-header-btn:visited {
    background: #004276;
    border: solid 1px #012b4c;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 24px;
    margin-bottom: 8px;
    margin-top: 16px;
  }

  .bi-pending-header-btn:active,
  .bi-pending-header-btn:focus,
  .bi-pending-header-btn:hover {
    background: #012b4c;
    border: solid 1px #012b4c;
    color: #fff;
    text-decoration: none;
  }

  .bi-pending-header-description {
    font-size: 24px;
    line-height: 32px;
    margin: 0 auto;
    max-width: 760px;
  }

  .bi-pending-header-title {
    color: #012b4c;
    font-size: 36px;
  }

  .bi-pending-share {
    margin-bottom: 64px;
    margin-top: 64px;
  }

  .bi-pending-share-btn,
  .bi-pending-share-btn:visited {
    background: #7a559f;
    border: solid 1px #61378b;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 24px;
  }

  .bi-pending-share-btn:active,
  .bi-pending-share-btn:focus,
  .bi-pending-share-btn:hover {
    background: #61378b;
    border: solid 1px #61378b;
    color: #fff;
    text-decoration: none;
  }

  .bi-pending-share-input {
    font-size: 16px;
    padding-bottom: 19px;
    padding-top: 19px;
  }

  .bi-pending-testimonials {
    background: #f2f9ff;
    margin: 0 auto 80px auto;
    max-width: 1140px;
    padding-bottom: 16px;
    padding-top: 16px;
  }

  .bi-pending-testimonials-title {
    font-size: 28px;
    font-weight: 300;
    line-height: 36px;
  }

  .bi-pending-share-title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
  }

  .bi-pending-header-title-icon {
    margin-right: 4px;
    vertical-align: top;
  }

  /** Bootstrap v3 small break **/
  @media (max-width: 991px) {
    .bi-pending-benefits {
      background-position: 0 1350px;
    }

    .bi-pending-benefits-container-benefit {
      margin-bottom: 48px;
    }
  }

  /** Bootstrap v3 x-small break **/
  @media (max-width: 767px) {
    .container {
      width: unset;
    }
    .bi-pending-benefits {
      background-position: 0 1450px;
    }
  }

  @media only screen and (max-width: 768px) {
    .bi-pending-header {
      padding-top: 10px;
    }
  }
}
