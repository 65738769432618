@import '../../../../styles/theme';

.edit-sidebar {
  overflow-y: auto;
  width: 296px;
  background: white;
  right: 0;
  position: fixed;
  z-index: 1040;
  border-left: 1px solid #eee;
  padding: 16px;

  @include media-breakpoint-down(xs) {
    width: 250px;
  }

  & > * {
    padding-bottom: 10px;
  }
}
