.product-container {
  padding: 0 5px;
  border-radius: 10px;
  position: relative;

  .product-label {
    display: none;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.most-popular {
    background: #dacde5;

    .product-label {
      display: block;
      position: absolute;
      top: 15px;
      left: 0;
      text-align: center;
      width: 100%;

      // Small devices (landscape phones, less than 768px)
      @media (max-width: 767.98px) {
        position: relative;
        top: 0;
        margin: 10px 0;
      }
    }
  }
}

.product-card {
  min-width: 250px;
  margin: 55px auto 20px auto;
  background: white;
  border-radius: 5px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.075);
  border: 1px solid #ddd;
  padding: 32px 15px 24px 15px;
  text-align: center;

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    min-width: 350px;
    margin: 20px auto;
  }

  &.confirmation-pane {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    border: 2px solid #7a559f;
    height: 100%;
    max-width: 250px;

    button {
      margin-top: 10px;
      width: 50%;
    }
  }

  &-title {
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 8px;
    margin-top: 0;
  }

  .product-pricing-title {
    margin-bottom: 8px;
  }

  .product-pricing-title-sign {
    font-size: 26px;
    vertical-align: top;
  }

  .product-pricing-title-value {
    font-size: 38px;
    font-weight: 400;
    line-height: 44px;
    margin-left: 2px;
  }

  .product-pricing-footnote {
    color: #9b9b9b;
    font-size: 14px;
    line-height: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 16px;
  }

  .product-counts {
    font-size: 18px;
    font-weight: 200;
    text-align: left;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: flex-start;

    .product-line {
      margin-bottom: 5px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      align-content: flex-start;
      justify-items: flex-start;

      .imgIcon {
        margin: 10px 20px;
      }

      .product-line-text {
        strong {
          margin-right: 7px;
        }
      }
    }
  }
}
