@import "~normalize.css";
@import '~@newslit/uikit/build/tailwind.css';
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import '~bootstrap/scss/bootstrap.scss';
@import 'ui';

body {
  font-family: 'Noto Sans', 'Ubuntu', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

* {
  font-family: 'Noto Sans', 'Ubuntu', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

$white: #fff;
$transparent: #eafef7;
$purple: #805ca3;
$twitter-blue: #55acee;
$tool-gray: #999999;
$tool-item-height: 45px;
$red: #D9534F;

btn-outline-purple {
  @include button-variant(
                  $white,
                  darken($white, 7.5%),
                  darken($white, 10%),
                  lighten($white, 5%),
                  lighten($white, 10%),
                  darken($white, 20%)
  );
}

.btn-white {
  @include button-variant(
                  $white,
                  darken($white, 7.5%),
                  darken($white, 10%),
                  lighten($white, 5%),
                  lighten($white, 10%),
                  darken($white, 20%)
  );
}

.btn-red {
  @include button-variant(
                  $red,
                  darken($red, 7.5%),
                  darken($red, 10%),
                  lighten($red, 5%),
                  lighten($red, 10%),
                  darken($red, 20%)
  );
}

.btn-circle-transparent {
  @include button-variant(
                  #fff,
                  darken($white, 7.5%),
                  darken($white, 10%),
                  lighten($white, 5%),
                  lighten($white, 10%),
                  darken($white, 20%)
  );
  height: 92px;
  width: 92px;
  display: block;
  border-color: white !important;
  border-radius: 92px !important;
}

.btn-settings {
  @include button-variant(
                  #ccc,
                  darken($white, 7.5%),
                  darken($white, 10%),
                  lighten($white, 5%),
                  lighten($white, 10%),
                  darken($white, 20%)
  );
  color: white;
  font-size: 15px;

  &:hover {
    color: black;
  }
}

.btn-purple {
  &.center-content {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    text-align: center;

    .imgIcon {
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  @include button-variant(
                  $purple,
                  darken($purple, 7.5%),
                  darken($purple, 10%),
                  lighten($purple, 5%),
                  lighten($purple, 10%),
                  darken($purple, 20%)
  );
}

.btn-group-toggle {
  .btn-outline-purple:hover {
    background: $purple !important;
    color: white !important;
    border: 1px solid $purple;
  }
}

.btn-circle {
  @include button-variant(
                  $purple,
                  darken($purple, 7.5%),
                  darken($purple, 10%),
                  lighten($purple, 5%),
                  lighten($purple, 10%),
                  darken($purple, 20%)
  );
  border-radius: 100px;
  height: 30px;
  font-size: 13px;
}

.btn-twitter {
  @include button-variant(
                  $twitter-blue,
                  darken($twitter-blue, 7.5%),
                  darken($twitter-blue, 10%),
                  lighten($twitter-blue, 5%),
                  lighten($twitter-blue, 10%),
                  darken($twitter-blue, 20%)
  );
  color: white;
}

.btn-outline-purple {
  @include button-outline-variant($purple, $white, $purple, $purple);
  color: $purple;
}

.btn-outline-gray {
  @include button-outline-variant(gray("400"), $white, gray("400"), gray("400"));
  color: gray("500");
}

.btn-outline-blue {
  @include button-outline-variant($twitter-blue, $white, $twitter-blue, $twitter-blue);
  color: $twitter-blue;
  flex-shrink: 0;
}

.btn-tool-button {
  @include button-variant(
                  $tool-gray,
                  darken($tool-gray, 7.5%),
                  darken($tool-gray, 10%),
                  lighten($tool-gray, 5%),
                  lighten($tool-gray, 10%),
                  darken($tool-gray, 20%)
  );
  color: white;
  font-size: smaller;
  font-weight: bolder;
  margin-right: 5px;
}

.AddReportKeyword {
  .inline-tag-editor {
    .keyword {
      font-size: 14px !important;
    }
  }
}

.tool-item-list {
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #979797;
  display: flex;
  flex-direction: column;

  .tool-item-list-header {
    height: $tool-item-height;
    line-height: $tool-item-height;
    text-align: left;
    padding-left: 10px;
    justify-self: center;
    border-radius: 3px 3px 0 0;
    background: #0275D8;
    color: white;
  }

  .tool-item {
    position: relative;
    height: $tool-item-height;
    border-top: 1px solid #ddd;
    padding-left: 10px;
    line-height: $tool-item-height;
    background: #F5F5F5;
    color: #0275D8;

    &.last {
      border-radius: 0 0 3px 3px;
    }

    button {
      opacity: 0;
      position: absolute;
      right: 0;
      top: 5px;
    }

    &:hover {
      button {
        opacity: 1;
      }
    }
  }
}

.alert-purple {
  background: $purple;
  border-radius: 0;
  color: white;
}

.beta {
  display: inline;
  background: #ebebeb;
  color: #78706c;
  padding: 4px 6px;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 2px;
  margin-left: 0.5rem;
  white-space: nowrap;
  vertical-align: middle;
}
