a.story-link {
  color: #144066;

  &.story-link-clicked {
    color: #888 !important;
  }
}

a.story-link:hover {
  color: #144066;
}
