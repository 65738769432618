
.sharedlink-card {
  padding: 1.25rem 1rem;
  border-bottom: 0.0625rem solid #e5e5e5;

  .sharedlink-footer {
    display: flex;
    grid-column: 1/span 3;
    justify-items: center;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-top: 10px;

    .share-count {
      background-color: #E8E8E8;
      color: #333;
      font-size: 1.2em;
      display: inline-block;
      padding: .35em .6em;
      font-weight: bold;
      line-height: 1;
      white-space: nowrap;
      text-align: center;
      border-radius: 5px;
      max-height: 30px;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}
