@import '../../styles/theme';

.EditBriefKeyword {

  .inline-tag-editor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start !important;
    align-content: flex-start !important;
    background-color: #fff;
    overflow: hidden;
    padding: 0 5px 5px 5px;
    border: 1px solid #eeeeee;
    border-radius: 0.25rem;
    cursor: text;
    min-height: 100px !important;

    input,
    input:focus {
      outline: none !important;
      border: none transparent !important;
      font-size: 13px;
      width: 7em;
      margin: 5px 3px 0 0;
      padding: 2px;
      flex-grow:1;
    }

    .keyword {
      color: #fff;
      border: #bbbbbb 1px solid;
      display: inline-block;
      border-radius: 20px;
      font-size: 12px;
      padding: 2px 10px;
      margin: 5px 3px 0 0;
      text-decoration: none;
      vertical-align: middle;

      span.keyword-remove {
        color: #fff;
        font-weight: bold;
        margin-left: 4px;
        opacity: 0.2;
        cursor: pointer;
        text-decoration: none !important;

        &.keyword-disabled {
          cursor: default !important;
          opacity: 0.1 !important;
        }
      }

      .keyword-remove:hover {
        opacity: 0.4;
      }

      &.exact-match {
        span:nth-child(1) {
          text-decoration: underline;
          font-weight: bolder;
        }
      }
    }
  }
}
