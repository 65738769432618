@import '../../../../styles/theme';

.CreateReportKeyword {
  position: relative;

  .notice-exact-match {
    display: none;
  }

  &.exact-match {
    .notice-exact-match {
      display: block;
      position: absolute;
      bottom: -8px;
      right: 0;
      padding: 5px;
      background: $purple;
      border-radius: 0px 0px 5px 5px;
      color: #fff;
      font-size: 0.8em;
    }
  }

  .inline-tag-editor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start !important;
    align-content: flex-start !important;
    background-color: #fff;
    overflow: hidden;
    padding: 0 5px 5px 5px;
    border: 1px solid #eeeeee;
    border-radius: 0.25rem;
    cursor: text;
    min-height: 100px !important;

    input,
    input:focus {
      outline: none !important;
      border: none transparent !important;
      font-size: 13px !important;
      width: 7em;
      margin: 5px 3px 0 0;
      padding: 2px;
      flex-grow:1;
    }

    .keyword {
      background: $twitter-blue;
      border: #396b92 1px solid;
      color: #fff !important;
      display: inline-block;
      border-radius: 20px;
      font-size: 13px;
      padding: 2px 10px;
      margin: 5px 3px 0 0;
      text-decoration: none;
      vertical-align: middle;

      span.keyword-remove {
        color: #fff;
        font-weight: bold;
        margin-left: 4px;
        opacity: 0.2;
        cursor: pointer;
        text-decoration: none !important;

        &.keyword-disabled {
          cursor: default !important;
          opacity: 0.1 !important;
        }
      }

      .keyword-remove:hover {
        opacity: 0.4;
      }

      &.exact-match {
        span:nth-child(1) {
          text-decoration: underline;
          font-weight: bolder;
        }
      }
    }
  }

  &.exact-match {
    .inline-tag-editor {
      border-radius: 10px 10px 0px 10px;
      border: 2px solid $purple !important;
    }
  }
}
