.deactivate-account-settings-link {
  font-weight: bold;
  font-size: 11px;
}

.deactivate-account-check {
  margin-bottom: 0.5em;

  label {
    font-weight: normal !important;
    cursor: pointer;
  }
}

.deactivate-account-actions {
  button {
    margin-right: 1em;
  }
}

.please-dont-go {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .deactivate-account-actions {
    a,
    button {
      margin-right: 0;
      margin-bottom: 0.5em;
      width: 100%;
    }
  }
}
