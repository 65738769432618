@import '../../../../styles/theme';

.story-card {
  display: grid;
  grid-template-columns: 1fr 16px auto;
  grid-template-rows: repeat(4, minmax(0px, auto));
  grid-column-gap: 0;
  grid-row-gap: 0;
  max-width: 600px;

  .story-image {
    flex-direction: row;
    grid-row: 1 / span 3;
    grid-column: 3/3;
    height: 100px;
    width: 100px;
    margin: 0;

    .image-container {
      width: 100%;
      height: 100%;
      display: block;
      background: #f2f2f2;
      object-fit: cover;
    }
  }

  .story-publisher {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.75rem;
    font-weight: bold;
  }

  .story-title {
    display: flex;
    grid-column: 1/span 1;
    line-height: 1.25;
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
    letter-spacing: unset !important;
  }

  .story-details {
    display: flex;
    grid-column: 1/span 1;
    color: #999;
    margin-top: 5px;
    font-size: 0.875rem;
  }

  .story-excerpt {
    display: flex;
    grid-column: 1/span 3;

    font-size: 1rem;
    line-height: 1.3;
    margin-top: 1rem;
    color: inherit;
    word-wrap: break-word;
  }
}

.story-card.hide-separator {
  border-top: 0;
}