.report-unsubscribe-panel-header {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  padding-bottom: 24px;
}

.report-unsubscribe-panel-body {
  padding-bottom: 10px;
}

.report-unsubscribe-loading-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  font-size: x-large;

  span {
    margin-left: 0.5em;
  }
}
