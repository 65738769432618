.define-screen-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  font-size: 16px;
  padding-left: 16px;
  padding-right: 16px;

  .contents {
    max-width: 600px;
    margin: auto;

    p {
      margin-top: 30px;
      line-height: 30px;
      text-align: justify;
    }
  }

  .topics {
    max-width: 600px;
    margin: 0 auto;
  }

  .form-instructions {
    max-width: 600px;
    margin: 0 auto;
    font-weight: bolder;

    span {
      display: inline-block;
      border: 1px solid black;
      color: #281c1c;
      padding: 3px 10px;
      border-radius: 20px;
      background: lightgray;
    }
  }

  .form-tag-editor {
    max-width: 600px;
    margin: 30px auto;

    .react-tagsinput {
      border: none;
    }

    .react-tagsinput-tag {
      background: white;
      border-color: #159ad7;
      color: #159ad7;
      border-radius: 20px;
      padding: 5px 10px;
      font-size: 20px;
    }

    .react-tagsinput-input {
      font-size: 20px;
      width: auto;
    }
  }

  .form-diagnostics {
    max-width: 600px;
    margin: 0 auto;
    color: gray;
    font-size: 14px;
  }

  .form-controls {
    max-width: 600px;
    margin: 20px auto 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 20px;
  }
}
