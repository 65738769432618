@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,700&display=swap');
@import 'panel';
@import 'fonts';

label {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.has-error {
  color: #a94442 !important;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}

.breadcrumb > li + li:before {
  content: '/\00a0';
  padding: 0 5px;
  color: #ccc;
}

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.breadcrumb > .active {
  color: #777;
}

.breadcrumb > li {
  display: inline-block;
}

.form-control[disabled],
.form-control[readOnly],
fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

.btn-primary {
  color: #fff;
  background-color: #2e3d4b;
  border-color: #24303b;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
}
