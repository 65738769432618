.recipients-tool-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto 30px;
  max-width: 260px;

  input {
    outline: none !important;
  }

  .recipients-tool-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    img {
      display: block;
      margin: 50px auto;
    }
  }
}
