.name-screen-container {
  max-width: 700px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    input {
      width: 300px;
    }
  }
}
