@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

span.white {
  color: white;
}

.gray-text {
  color: #979797;
}

.blue-text {
  color: #144066;
}

.intense-blue-text {
  color: #0767ED;
}

.light-blue-text {
  color: #209ad7 !important;
}

.deep-light-blue-text {
  color: #0275D8 !important;
}

.black-text {
  color: #000 !important;
}

.align-left {
  text-align: left;
}

.keyboard-key {
  border: 1px solid #7d7d7d;
  border-radius: 50px;
  padding: 2px 10px;
  background: #eee;
  font-size: 12px;
  font-weight: bold;
}

.carbon-text {
  color: #4C4B4B;
}

.blue-tag {
  background: #209ad7 !important;
}

.purple-tag {
  background: $purple !important;
}

.carbon-tag {
  background: #4C4B4B !important;
}

.bold-h0 {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 0.5px;
  text-align: center;
  width: 100%;
}

.bold-h1 {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.5px;
}

.bold-h2 {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.bold-h3 {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.bold-h4 {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.bold-h5 {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.light-h0 {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: lighter;
  font-size: 30px;
  letter-spacing: 0.5px;
}

.light-h1 {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: lighter;
  font-size: 20px;
  letter-spacing: 0.5px;
}

.light-h2 {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: lighter;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.light-h3 {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: lighter;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.light-h4 {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: lighter;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.light-h5 {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: lighter;
  font-size: 12px;
  letter-spacing: 0.5px;
}

span.reportName {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.5px;
}
