.slack-tool-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto 30px;
  max-width: 260px;

  .slack-tool-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    img {
      display: block;
      margin: 50px auto;
    }
  }

  .add-to-slack-wrapper {
    display: flex;
    flex-direction: row;

    .js-add-to-slack {
      margin-right: 0.5em;
    }
  }

  .slack-button {
    display: flex !important;
    flex-direction: row;
    align-items: center;

    .slack-logo {
      width: 1.5em;
      height: 1.5em;
      margin-right: 0.5em;
      background-image: url('../../assets/images/slack-logo.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .slack-button-text {
      margin-right: 0.5em;
    }
  }

}
