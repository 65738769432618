@import '../../styles/theme';

.story-btn-share {
  padding-right: 6px;
  color: #333;
  opacity: .54;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .imgIcon {
    object-fit: contain;
  }
}
