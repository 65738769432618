.notification-bar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .notification-bar {
    position: fixed;
    margin: 0 auto;
    text-align: center;
    z-index: 100000;

    .message {
      background: #f9edbe;
      font-weight: 700;
      padding: 6px 10px;
      border: 1px solid transparent;
      border-color: #f0c36d;
      border-radius: 2px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
}
