.edit-brief-schedule-hours-selector {
  width: auto !important;
}

.schedule-tool-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto 30px;
  max-width: 260px;

  .tool-section {
    margin-bottom: 20px;

    .bold-h2 {
      margin-bottom: 20px;
    }

    .btn-outline-purple.mod-scheduling {
      &:hover {
        color: #805ca3;
        background-color: #fff;
        border-color: #805ca3;
      }

      &.active:hover {
        color: #fff;
        background-color: #805ca3;
      }

      &:focus,
      &.focus {
        box-shadow: unset !important;
      }
    }
  }
}
