.activate-account-content-header {
  font-family: Ubuntu, 'Noto Sans', serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px !important;
  margin-top: 10px !important;
}

.activate-account-content-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  font-size: large;

  span {
    margin-left: 0.5em;
  }
}
