@import '../../styles/theme';

.BriefStoryCard {
  cursor: default;
  padding: 16px;

  @include media-breakpoint-up(md) {
    @media (hover: hover) {
      &:hover {
        & .share-options {
          visibility: visible !important;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &:not(:first-child) .BriefStoryCard__Body:before {
      position: relative;
      top: -16px;
      right: 0;
      bottom: auto;
      left: 0;
      z-index: 1;
      content: '';
      display: block;
      height: 1px;
      background: #9aa0a6;
      opacity: .23;
    }
  }

  .StoryCard {
    display: grid;
    grid-template-columns: 1fr 16px auto;
    grid-template-rows: repeat(6, minmax(0px, auto));
    grid-column-gap: 0;
    grid-row-gap: 0;

    &__Image {
      flex-direction: row;
      grid-row: 1 / span 3;
      grid-column: 3/3;
      height: 100px;
      width: 100px;
      margin: 0;

      .image-container {
        width: 100%;
        height: 100%;
        display: block;
        background: #f2f2f2 no-repeat;
        background-size: cover;
      }
    }

    &__Publisher {
      display: flex;
      flex-wrap: wrap;
      font-size: 0.70rem;
      font-weight: bold;
    }

    &__Title {
      display: flex;
      grid-column: 1/span 1;
      line-height: 1.25;
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0;
    }

    &__Details {
      display: flex;
      grid-column: 1/span 1;
      color: #666;
      margin-top: 5px;
      font-size: 0.8125rem;
    }

    &__Excerpt {
      display: flex;
      grid-column: 1/span 3;

      font-size: 0.8125rem;
      line-height: 1.3;
      margin-top: 10px;
      color: inherit;
      opacity: .8;
      word-wrap: break-word;
    }

    &__Info-bar {
      display: flex;
      grid-column: 1/span 3;
      justify-items: center;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      padding-top: 10px;

      @include media-breakpoint-up(md) {
        @media (hover: hover) {
          .share-options {
            visibility: hidden;
          }
        }
      }

      .score-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        .score-label {
          text-align: center;
          font-size: 1.15em;
        }

        .score {
          width: 60px;
          overflow: hidden;
          height: 8px;
          background-color: #f5f5f5;
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
          border-radius: 100px;

          .score-bar {
            float: left;
            border-radius: 100px;
            width: 0;
            height: 100%;
            font-size: 10px;
            line-height: 20px;
            color: #fff;
            text-align: center;
            background-color: #2e3d4b;
            box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
            transition: width 0.6s ease;
          }
        }
      }
    }
  }
}
