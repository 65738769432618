.periodicity-switch {
  margin: 32px auto;
  width: 292px;

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    margin: 15px auto;
  }

  &-annually,
  &-input,
  &-monthly {
    float: left;
  }

  &-annually,
  &-monthly {
    margin: 8px 16px;
    width: 85px;
  }

  &-annually-footnote {
    color: #48874a;
    font-size: 12px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 58px;
    height: 32px;

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  /* Hide default HTML checkbox */
  .switch input {
    display: none;
  }

  input:checked + .slider {
    background-color: #1565A2;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #1565A2;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}
