.new-preview {
  .refine-screen-container {
    height: 80vh;
    .form-controls {
      margin-top:50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }
    .stories-preview {
      border:1px solid #f1f1f1;
      border-radius:2px;
      transform: skew(0turn, -2deg);
      width: 600px;
    }
  }
}

.refine-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-top: 16px;

  .stories-preview {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    max-width: 600px;
    .empty-preview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      padding: 1em;
      font-size: 0.8em;
    }
  }
}
