@import '../../styles/theme';

.circular-progress-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  label {
    margin: 0 5px 0 0;
  }

  .done {
    width: 15px;
    height: 15px;
    border-radius: 100px;
    background: $purple;
    margin: 0 2px;
  }

  .pending {
    width: 15px;
    height: 15px;
    border-radius: 100px;
    background: gray;
    margin: 0 2px;
  }
}
