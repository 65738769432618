.wizard-layout {
  width: 100%;
  position: sticky;
  display: flex;
  top: 0;
  background: white;
  z-index: 850;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-left: 16px;
  padding-right: 16px;
}

.wizard-title-container {
  width: 100%;
  max-width: 800px;
  text-align: center;
  padding-bottom: 10px;

  h3 {
    margin-top: 10px;
  }
}

ul.progress-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  max-width: 800px;

  li {
    list-style: none;
    height: 50px;
    display: flex;
    width: 30%;
    flex-direction: column;

    span {
      font-size: 16px;

      &.current {
        font-weight: bolder;
      }
    }

    .bar {
      height: 10px;
      width: 100%;
      border-radius: 20px;
      background: #209ad7;
      opacity: 0.3;
      margin-bottom: 10px;

      &.completed {
        opacity: 1;
      }

      &.working {
        animation: working-flash 1s infinite;
        animation-timing-function: linear;
      }
    }
  }
}

@keyframes working-flash {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
