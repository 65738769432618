.connect-report-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  .connect-report-item-name {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .connect-report-item-slack-icon {
    width: 1.5em;
    height: 1.5em;
    background-image: url(../../assets/images/slack-logo.svg);
  }
}
