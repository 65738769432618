.questionnaire-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    text-align: center;
  }

  p {
    max-width: 600px;
    text-align: justify;

  }


  .stepInformation {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 115px;
  }

  .buttonBar {
    margin-top: 20px;
  }

  .question-line {
    margin-top: 30px;
    width: 500px;

    .dropdown-menu {
      width: 100%;
    }

    .dropdown {
      margin-bottom: 0;

      &.no-highlight {
        button {
          border: 1px solid gray;
        }
      }
    }
  }

  .circular-progress-bar {
    opacity: 1 !important;

    .pending {
      opacity: 0.7;
    }

    .done {
      background: #0275D8;
      opacity: 1;
    }
  }
}
